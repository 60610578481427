<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/checkCourse' }">打卡课程</el-breadcrumb-item>
                <el-breadcrumb-item>课程详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title" style="">
            <div style="padding:10px">
                <div class="fl-l" style="width: 150px;"><img width="150px" :src="pic" /></div>
                <div class="fl-l margin-l-sm">
                    <p class="title">{{detail.name}}</p>
                    <p class="price">￥ {{detail.amount}}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">

            <el-tabs v-model="activeName" type="card" @tab-click="change" >
                <el-tab-pane label="课时管理" name="manage" path="manage" ></el-tab-pane>
                <el-tab-pane label="学员管理" name="student" path="student" v-if="$store.state.user.info.role === 1"></el-tab-pane>
            </el-tabs>
            <router-view  :classTotal="detail.total_class" :courseName="detail.name">
            </router-view>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "detail",
        data(){
            return {
                loading:false,
                activeName: 'manage',
                pic:'',
                amount:0,
                name:'',
                imageUrl:config.imageUrl,
                id:'',
                detail:[]
            };
        },
        methods: {
            ...mapActions('punch',['getCheckDetail']),
            change(tab, event) {
                let route = tab.$attrs.path
                console.log(route)
                this.$router.push('/checkCourse/detail/'+this.$route.params.id+'/'+route)
            },
            getDetail(){
                this.loading = true
                this.getCheckDetail(this.id).then(res => {
                    this.detail = res.data
                    this.pic = this.imageUrl + '/' +res.data.pic
                }).finally(()=>{
                    this.loading = false
                })
            }
        },
        mounted() {
            this.id = this.$route.params.id

            let arr = this.$route.path.split('/')
            let activeName = arr[arr.length-1]
            this.activeName = activeName

            this.getDetail()

        }
    }
</script>

<style>
    .course-detail-title{
        margin:10px;
        background: #fafafa;
        overflow: hidden;
    }
    .title{
        font-size: 18px;
        margin-top:5px;
    }
    .price{
        margin-top:45px;
        font-size: 16px;
    }
</style>